











































































import {Component, Vue} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import DepartmentsModule from "@/store/DepartmentsModule";
import DepartmentContent from "@/models/DepartmentContent";
import DepartmentService from "@/services/DepartmentService";

@Component
export default class DepartmentView extends Vue {
    loading: boolean = false
    departmentsModule: DepartmentsModule = getModule(DepartmentsModule)
    department: DepartmentContent | undefined = new DepartmentContent()
    headers = [
        { text: "Profesores", value: "fullName" },
        { text: "Cargo", value: "position" },
        { text: "Contacto", value: "email" },
        { text: "Horario de atencion", value: "schedule" }
    ]


    created() {
        if (this.departmentsModule.departments.length <= 0) {
            DepartmentService.getDepartment(this, parseInt(this.$route.params.id))
        } else {
            this.department = this.departmentsModule.departments.find(d => {
                return d.id == parseInt(this.$route.params.id)
            })
        }
    }
}
